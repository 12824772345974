const hotelactioncommissiontype = {
    "TYPES" : [
        {
            Value : 'COMMISSION',
            Title : 'KOMISYON',
        },
        {
            Value : 'NET',
            Title : 'NET',
        },],
};
export default hotelactioncommissiontype;