const hotelActionType = {
  "TYPES" : [
    {
      Value : 'EB',
      Title : 'EB İNDİRİMİ (ORAN)',
      Shorthand : 'EB',
    },
    {
      Value : 'DAY',
      Title : 'GÜN AKSİYONU',
      Shorthand: 'DAY',
    },
    {
      Value : 'AMOUNT',
      Title : 'TUTAR İNDİRİMİ',
      Shorthand: 'AMN',
    },
    {
      Value : 'COMMISSION',
      Title : 'KOMİSYON',
      Shorthand: 'COM',
    },
  ],
};
export default hotelActionType;
