const hotelActionDiscountType = {
  "EBTYPES" : [
    {
      "Value" : 'GRADUAL',
      "Title" : 'KADEMELİ',
    },
    {
      "Value" : 'CUMULATIVE',
      "Title" : 'KÜMÜLATİF',
    }],
  "AMNDISCOUNT" : [
    {
      "Value" : 'PERPERSON',
      "Title" : 'KİŞİ BAŞI',
    },
    {
      "Value" : 'CUMULATIVE',
      "Title" : 'TOPLAM',
    }],
  "AMNTYPES" : [
    {
      "Value" : 'DAILY',
      "Title" : 'GÜNLÜK',
    },
    {
      "Value" : 'CUMULATIVE',
      "Title" : 'TOPLAM',
    }],
  "DAYTYPES" : [
    {
      "Value" : 'YES',
      "Title" : 'EVET',
    },
    {
      "Value" : 'NO',
      "Title" : 'HAYIR',
    }],
};
export default hotelActionDiscountType;
