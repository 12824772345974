const includeType = {
  "TYPES" : [
    {
      Value : 'INCLUDE',
      Title : 'DAHİL',
      Shorthand : 'IN',
    },
    {
      Value : 'EXCLUDE',
      Title : 'HARİÇ',
      Shorthand: 'EX',
    },
  ],
};
export default includeType;
