
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import weekDaysList from "@/core/data/weekdays";
import hotelActionType from "@/core/data/hotelactiontype";
import hotelActionMinAccType from "@/core/data/hotelactionminacctype";
import hotelActionDiscountType from "@/core/data/hotelactiondiscounttype";
import hotelactioncommissiontype from "@/core/data/hotelactioncommissiontype";
import includeType from "@/core/data/includetype";

interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemAccStart: string;
    itemAccFinish: string;
    itemType: string;
    itemCommissionType: string;
    itemMinDayType: string;
    itemMinDay: string;
    itemRoom: string;
    itemConcept: string;
    itemValidDays: Array<any>;
    itemEntryDays: Array<any>;
    itemPerCostDiscount: string;
    itemPerAccountDiscount: string;
    itemPerCostType: string;
    itemPerAccountType: string;
    itemPerPosterRate: string;
    itemAmnCostDiscount: string;
    itemAmnAccountDiscount: string;
    itemAmnCostType: string;
    itemAmnAccountType: string;
    itemAmnType: string;
    itemAmnPosterRate: string;
    itemDayRepeat: string;
    itemMarket: string;
    itemMarketStatus: string;
    itemAccount: string;
    itemAccountStatus: string;
    itemMulti: boolean;
    itemApplyCheckIn: boolean;
}

export default defineComponent({
    name: "update-item-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
        }
    },
    methods: {},
    props: {
        hotelID: String,
        itemID: String,
    },
    watch: {
        itemID() {
            const payloadit = {
                "ID": this.itemID
            };
            this.accTagAddStatus = false;
            this.marTagAddStatus = false;
            store.dispatch(Actions.HOTEL_ACTIONS_DETAILS, payloadit);
        },
    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);


        function setDatePicker(sDate, fDate, acsDate, acfDate) {
            let myConfigS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: sDate,
            };

            let myConfigF = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: fDate,
            };

            let myConfigAccS = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: acsDate,
            };

            let myConfigAccF = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                defaultDate: acfDate,
            };

            let tmpFinishDate = flatpickr("#updateFinishDate", myConfigF);
            let tmpStartDate = flatpickr("#updateStartDate", myConfigS);
            let tmpAccStartDate = flatpickr("#updateAccStartDate", myConfigAccS);
            let tmpAccFinishDate = flatpickr("#updateAccFinishDate", myConfigAccF);

        }

        const myDetails2 = computed(() => {
            return store.getters.hotelDetails;
        });

        const payloadrm = {
            HotelID: props.hotelID,
            is_active: "active",
        }

        store.dispatch(Actions.HOTEL_ROOMS_LIST, payloadrm);
        const myRoomsList = computed(() => {
            return store.getters.hotelRoomsList;
        });
        const payloadcn = {
            HotelID: props.hotelID,
            is_active: "active",
        }
        store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payloadcn);
        const myConceptList = computed(() => {
            return store.getters.hotelConceptsList;
        });

        const myDetails = computed(() => {
            let tmpArry = store.getters.hotelActionsDetails;
            setData(tmpArry);
            return tmpArry;

        });

        const setData = (tmpArry) => {
            if (tmpArry.ID !== undefined) {
                newItemData.value.itemTitle = tmpArry.Title;
                newItemData.value.itemStart = dateConvert.method.dateToflatpicker(tmpArry.StartDate);
                newItemData.value.itemFinish = dateConvert.method.dateToflatpicker(tmpArry.FinishDate);
                newItemData.value.itemAccStart = dateConvert.method.dateToflatpicker(tmpArry.AccStartDate);
                newItemData.value.itemAccFinish = dateConvert.method.dateToflatpicker(tmpArry.AccFinishDate);
                newItemData.value.itemType = tmpArry.Type;
                newItemData.value.itemCommissionType = tmpArry.CommissionType;
                newItemData.value.itemRoom = tmpArry.Room;
                newItemData.value.itemConcept = tmpArry.Concept;
                newItemData.value.itemMinDayType = tmpArry.MinDayStatus;
                newItemData.value.itemMinDay = tmpArry.MinDay;
                newItemData.value.itemEntryDays = JSON.parse(tmpArry.EntryDay);
                newItemData.value.itemValidDays = JSON.parse(tmpArry.ValidDay);
                newItemData.value.itemMarketStatus = tmpArry.MarketStatus;
                newItemData.value.itemMarket = tmpArry.Market;
                newItemData.value.itemAccountStatus = tmpArry.AccountStatus;
                newItemData.value.itemAccount = tmpArry.Account;

                let myTmpActionType = JSON.parse(tmpArry.Config);
                if (newItemData.value.itemType == 'EB' || newItemData.value.itemType == 'COMMISSION') {
                    newItemData.value.itemPerCostDiscount = myTmpActionType.CostDiscountRate;
                    newItemData.value.itemPerCostType = myTmpActionType.CostDiscountType;
                    newItemData.value.itemPerAccountDiscount = myTmpActionType.AccountDiscountRate;
                    newItemData.value.itemPerAccountType = myTmpActionType.AccountDiscountType;
                    newItemData.value.itemPerPosterRate = myTmpActionType.PosterRate;
                    newItemData.value.itemApplyCheckIn = myTmpActionType.CheckInStatus;
                    newItemData.value.itemMulti = myTmpActionType.MultiStatus;
                } else if (newItemData.value.itemType == 'AMOUNT') {
                    newItemData.value.itemAmnCostDiscount = myTmpActionType.CostDiscountRate;
                    newItemData.value.itemAmnCostType = myTmpActionType.CostDiscountType;
                    newItemData.value.itemAmnAccountDiscount = myTmpActionType.AccountDiscountRate;
                    newItemData.value.itemAmnAccountType = myTmpActionType.AccountDiscountType;
                    newItemData.value.itemAmnPosterRate = myTmpActionType.PosterRate;
                    newItemData.value.itemApplyCheckIn = myTmpActionType.CheckInStatus;
                    newItemData.value.itemMulti = myTmpActionType.MultiStatus;
                } else if (newItemData.value.itemType == 'DAY') {
                    newItemData.value.itemDayRepeat = myTmpActionType.RepeatStatus;
                    newItemData.value.itemApplyCheckIn = myTmpActionType.CheckInStatus;
                    newItemData.value.itemMulti = myTmpActionType.MultiStatus;
                }

                if (tmpArry.StartDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.StartDate), dateConvert.method.convertToDate(tmpArry.FinishDate), dateConvert.method.convertToDate(tmpArry.AccStartDate), dateConvert.method.convertToDate(tmpArry.AccFinishDate))
            }
        }

        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemAccStart: "",
            itemAccFinish: "",
            itemType: "",
            itemCommissionType: "",
            itemMinDayType: "",
            itemMinDay: "",
            itemRoom: "",
            itemConcept: "",
            itemValidDays: [true, true, true, true, true, true, true],
            itemEntryDays: [true, true, true, true, true, true, true],
            itemPerCostDiscount: "",
            itemPerAccountDiscount: "",
            itemPerCostType: "",
            itemPerAccountType: "",
            itemPerPosterRate: "",
            itemAmnCostDiscount: "",
            itemAmnAccountDiscount: "",
            itemAmnCostType: "",
            itemAmnAccountType: "",
            itemAmnType: "",
            itemAmnPosterRate: "",
            itemDayRepeat: "",
            itemMarket: "",
            itemMarketStatus: "",
            itemAccount: "",
            itemAccountStatus: "",
            itemMulti: true,
            itemApplyCheckIn: true,
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Bitiş Tarihi"),
            itemType: Yup.string().required().label("Komisyon Türü"),
        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            let plConfig;
            if (newItemData.value.itemType == 'EB' || newItemData.value.itemType == 'COMMISSION') {
                plConfig = {
                    'CostDiscountRate': newItemData.value.itemPerCostDiscount,
                    'CostDiscountType': newItemData.value.itemPerCostType,
                    'AccountDiscountRate': newItemData.value.itemPerAccountDiscount,
                    'AccountDiscountType': newItemData.value.itemPerAccountType,
                    'PosterRate': newItemData.value.itemPerPosterRate,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            } else if (newItemData.value.itemType == 'AMOUNT') {
                plConfig = {
                    'CostDiscountRate': newItemData.value.itemAmnCostDiscount,
                    'CostDiscountType': newItemData.value.itemAmnCostType,
                    'AccountDiscountRate': newItemData.value.itemAmnAccountDiscount,
                    'AccountDiscountType': newItemData.value.itemAmnAccountType,
                    'PosterRate': newItemData.value.itemAmnPosterRate,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            } else if (newItemData.value.itemType == 'DAY') {
                plConfig = {
                    'RepeatStatus': newItemData.value.itemDayRepeat,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            }

            const payload1 = {
                ID: props.itemID,
                HotelID: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                AccStartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemAccStart),
                AccFinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemAccFinish),

                Market: (typeof newItemData.value.itemMarket != "string") ? (JSON.stringify(newItemData.value.itemMarket)).replace(/\\/g, '') : '""',
                Account: (typeof newItemData.value.itemAccount != "string") ? (JSON.stringify(newItemData.value.itemAccount)).replace(/\\/g, '') : '""',
                MarketStatus: newItemData.value.itemMarketStatus,
                AccountStatus: newItemData.value.itemAccountStatus,
                Type: newItemData.value.itemType,
                MinDayStatus: newItemData.value.itemMinDayType,
                MinDay: newItemData.value.itemMinDay,
                EntryDay: JSON.stringify(newItemData.value.itemEntryDays),
                ValidDay: JSON.stringify(newItemData.value.itemValidDays),
                Room: newItemData.value.itemRoom,
                Concept: newItemData.value.itemConcept,
                Config: JSON.stringify(plConfig),
                CommissionType:newItemData.value.itemCommissionType,
            }

            store.dispatch(Actions.HOTEL_ACTIONS_EDIT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getHotelActionsErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            return store.getters.accountList;
        });

        let marTagAddStatus = false;
        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data && myDetails.value.ID) {
                let tmpList = [];
                let tmpSelList = [];
                let selData = JSON.parse(myDetails.value.Market);
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myMarkets.value.data[i].ID) {
                            tmpSelList[is] = {
                                value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                                title: (myMarkets.value.data[i].ShortTitle).trim(),
                                cur: myMarkets.value.data[i].Cur,
                                type: 'market',
                                ID: myMarkets.value.data[i].ID,
                            };
                        }
                    }
                }

                if (marTagAddStatus == false) {
                    setTagInput(tmpList)
                    marTagAddStatus = true;
                }
                let tags = (document.getElementById('myTagsMarkets') as HTMLInputElement);
                if (tags.value != '') tagifyMarket.removeAllTags();
                if (tmpSelList.length > 0) {
                    tagifyMarket.addTags(tmpSelList);
                }

                return tmpList;

            } else {
                return [];
            }
        });

        let accTagAddStatus = false;
        let tmpSelList = [];
        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data !== undefined && myDetails.value.ID) {
                let tmpList = [];

                let selData = JSON.parse(myDetails.value.Account);

                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Accounts',
                        ID: myAccounts.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myAccounts.value.data[i].ID) {
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            tmpSelList[is] = {
                                value: (myAccounts.value.data[i].Title).trim(),
                                title: (myAccounts.value.data[i].Title).trim(),
                                type: 'Accounts',
                                ID: myAccounts.value.data[i].ID,
                            };
                        }

                    }
                }
                if (accTagAddStatus == false) {
                    setTagInputAccounts(tmpList)
                    accTagAddStatus = true;
                }
                let tags = (document.getElementById('myTagsAccounts') as HTMLInputElement);
                if (tags.value != '') tagifyAccount.removeAllTags();
                if (tmpSelList.length > 0) {
                    tagifyAccount.addTags(tmpSelList);
                }
                return tmpList;
            } else {
                return [];
            }
        });

        function testFunc() {
            tagifyAccount.removeAllTags();
            //tagifyAccount.addTags(tmpSelList);
        }

        let tagifyMarket;
        let tagifyAccount;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInput]');
// init Tagify script on the above inputs

            tagifyMarket = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });


        }
        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputAccount]');
// init Tagify script on the above inputs
            tagifyAccount = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });

        }

        function onAccountChange(e) {
            if (e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemAccount = tmpObj;
            } else {
                let tmpObj = "";
                newItemData.value.itemAccount = tmpObj;
            }
        }

        function onMarketChange(e) {
            if (e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.itemMarket = tmpObj;
            } else {
                let tmpObj = "";
                newItemData.value.itemMarket = tmpObj;
            }
        }

        return {
            myDetails,
            myDetails2,
            myRoomsList,
            myConceptList,
            newItemData,
            myTagsMarkets,
            myTagsAccounts,
            validationSchema,
            submit,
            onAccountChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            roomProfitType,
            dateConvert,
            accTagAddStatus,
            marTagAddStatus,
            testFunc,
            tmpSelList,
            weekDaysList,
            hotelActionType,
            hotelActionMinAccType,
            hotelActionDiscountType,
            hotelactioncommissiontype,
            includeType,
        };
    },
});
