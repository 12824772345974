
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import Tagify from '@yaireo/tagify';
import roomProfitType from "@/core/data/roomprofittype";
import {dateConvert} from "@/core/filters/datatime";
import weekDaysList from "@/core/data/weekdays";
import hotelActionType from "@/core/data/hotelactiontype";
import hotelActionMinAccType from "@/core/data/hotelactionminacctype";
import hotelActionDiscountType from "@/core/data/hotelactiondiscounttype";
import hotelactioncommissiontype from "@/core/data/hotelactioncommissiontype";
import includeType from "@/core/data/includetype";


interface newItemData {
    itemTitle: string;
    itemStart: string;
    itemFinish: string;
    itemAccStart: string;
    itemAccFinish: string;
    itemType: string;
    itemCommissionType: string;
    itemMinDayType: string;
    itemMinDay: string;
    itemRoom: string;
    itemConcept: string;
    itemValidDays: Array<any>;
    itemEntryDays: Array<any>;
    itemPerCostDiscount: string;
    itemPerAccountDiscount: string;
    itemPerCostType: string;
    itemPerAccountType: string;
    itemPerPosterRate: string;
    itemAmnCostDiscount: string;
    itemAmnAccountDiscount: string;
    itemAmnCostType: string;
    itemAmnAccountType: string;
    itemAmnType: string;
    itemAmnPosterRate: string;
    itemDayRepeat: string;
    itemMarket: string;
    itemMarketStatus: string;
    itemAccount: string;
    itemAccountStatus: string;
    itemMulti: boolean;
    itemApplyCheckIn: boolean;
}

export default defineComponent({
    name: "new-over-commission-modal",
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    data() {
        return {
            startpicker: null,
            endpicker: null,
            accStartpicker: null,
            accEndpicker: null,
        }
    },
    mounted() {
        this.datapicker();
        return true;
    },
    methods: {
        datapicker() {
            let config = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
            };
            this.startpicker = flatpickr("#kt_datepicker_1", config);
            this.endpicker = flatpickr("#kt_datepicker_2", config);
            this.accStartpicker = flatpickr("#kt_datepicker_3", config);
            this.accEndpicker = flatpickr("#kt_datepicker_4", config);
        },
    },
    props: {
        hotelID: String,
    },
    setup(props) {
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const newRoomModalRef = ref<null | HTMLElement>(null);


        const myDetails2 = computed(() => {
            return store.getters.hotelDetails;
        });

        const payloadrm = {
            HotelID: props.hotelID,
            is_active: "active",
        }

        store.dispatch(Actions.HOTEL_ROOMS_LIST, payloadrm);
        const myRoomsList = computed(() => {
            return store.getters.hotelRoomsList;
        });
        const payloadcn = {
            HotelID: props.hotelID,
            is_active: "active",
        }
        store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payloadcn);
        const myConceptList = computed(() => {
            return store.getters.hotelConceptsList;
        });

        const newItemData = ref<newItemData>({
            itemTitle: "",
            itemStart: "",
            itemFinish: "",
            itemAccStart: "",
            itemAccFinish: "",
            itemType: "",
            itemCommissionType: "",
            itemMinDayType: "",
            itemMinDay: "",
            itemRoom: "",
            itemConcept: "",
            itemValidDays: [true, true, true, true, true, true, true],
            itemEntryDays: [true, true, true, true, true, true, true],
            itemPerCostDiscount: "",
            itemPerAccountDiscount: "",
            itemPerCostType: "",
            itemPerAccountType: "",
            itemPerPosterRate: "",
            itemAmnCostDiscount: "",
            itemAmnAccountDiscount: "",
            itemAmnCostType: "",
            itemAmnAccountType: "",
            itemAmnType: "",
            itemAmnPosterRate: "",
            itemDayRepeat: "",
            itemMarket: "",
            itemMarketStatus: "",
            itemAccount: "",
            itemAccountStatus: "",
            itemMulti: true,
            itemApplyCheckIn: true,
        });

        const validationSchema = Yup.object().shape({
            itemTitle: Yup.string().required().label("Başlık"),
            itemStart: Yup.string().required().label("Rezervasyon Başlangıç Tarihi"),
            itemFinish: Yup.string().required().label("Rezervasyon Bitiş Tarihi"),
            itemAccStart: Yup.string().required().label("Konaklama Başlangıç Tarihi"),
            itemAccFinish: Yup.string().required().label("Konaklama Bitiş Tarihi"),
            itemType: Yup.string().required().label("Kampanya Türü"),
            //itemCommissionType: Yup.string().required().label("Komisyon Türü"),
            itemMinDayType: Yup.string().required().label("Min. Geceleme Türü"),
            itemMinDay: Yup.string().required().label("Min. Geceleme"),
            itemRoom: Yup.string().required().label("Oda"),
            itemConcept: Yup.string().required().label("Konaklama"),

        });
        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }
            //Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");
            let plConfig;
            if (newItemData.value.itemType == 'EB' || newItemData.value.itemType == 'COMMISSION') {
                plConfig = {
                    'CostDiscountRate': newItemData.value.itemPerCostDiscount,
                    'CostDiscountType': newItemData.value.itemPerCostType,
                    'AccountDiscountRate': newItemData.value.itemPerAccountDiscount,
                    'AccountDiscountType': newItemData.value.itemPerAccountType,
                    'PosterRate': newItemData.value.itemPerPosterRate,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            } else if (newItemData.value.itemType == 'AMOUNT') {
                plConfig = {
                    'CostDiscountRate': newItemData.value.itemAmnCostDiscount,
                    'CostDiscountType': newItemData.value.itemAmnCostType,
                    'AccountDiscountRate': newItemData.value.itemAmnAccountDiscount,
                    'AccountDiscountType': newItemData.value.itemAmnAccountType,
                    'PosterRate': newItemData.value.itemAmnPosterRate,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            } else if (newItemData.value.itemType == 'DAY') {
                plConfig = {
                    'RepeatStatus': newItemData.value.itemDayRepeat,
                    'CheckInStatus': newItemData.value.itemApplyCheckIn,
                    'MultiStatus': newItemData.value.itemMulti
                };
            }

            const payload1 = {
                HotelID: myDetails2.value.ID,
                Title: newItemData.value.itemTitle,
                StartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemStart),
                FinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemFinish),
                AccStartDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemAccStart),
                AccFinishDate: dateConvert.method.flatpickerToDateYM(newItemData.value.itemAccFinish),

                Market: JSON.stringify(newItemData.value.itemMarket),
                Account: JSON.stringify(newItemData.value.itemAccount),
                MarketStatus: newItemData.value.itemMarketStatus,
                AccountStatus: newItemData.value.itemAccountStatus,
                Type: newItemData.value.itemType,
                CommissionType: newItemData.value.itemCommissionType,
                MinDayStatus: newItemData.value.itemMinDayType,
                MinDay: newItemData.value.itemMinDay,
                EntryDay: JSON.stringify(newItemData.value.itemEntryDays),
                ValidDay: JSON.stringify(newItemData.value.itemValidDays),
                Room: newItemData.value.itemRoom,
                Concept: newItemData.value.itemConcept,
                Config: JSON.stringify(plConfig),
            }
            console.log(payload1);

            store.dispatch(Actions.HOTEL_ACTIONS_INSERT, payload1)
                .then(() => {
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(newRoomModalRef.value);
                        // Go to page after successfully login
                        //router.push({name: "userlist"});
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });


        };

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            const tmp = store.getters.accountList;
            //console.log(tmp)
            return tmp;
        });

        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data) {
                let tmpList = [];
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };

                }
                setTagInput(tmpList)
                return tmpList;

            } else {
                return [];
            }
        });

        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data !== undefined) {
                let tmpList = [];
                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Departments',
                        ID: myAccounts.value.data[i].ID,
                    };

                }
                setTagInputAccounts(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });


        let tagify;
        let tagify2;
        const setTagInput = (wList) => {
            var input = document.querySelector('input[name=myTagsInput]');
// init Tagify script on the above inputs
            tagify = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });


        }
        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsInputAccounts]');
// init Tagify script on the above inputs
            tagify2 = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });


        }

        function onAccountChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemAccount = tmpObj;
        }

        function onMarketChange(e) {
            //console.log(e.target.value)
            let tmpObj = JSON.parse(e.target.value);
            newItemData.value.itemMarket = tmpObj;
        }

        return {
            myDetails2,
            myRoomsList,
            myConceptList,
            newItemData,
            myTagsMarkets,
            myTagsAccounts,
            validationSchema,
            submit,
            onAccountChange,
            onMarketChange,
            submitButtonRef,
            newRoomModalRef,
            roomProfitType,
            dateConvert,
            weekDaysList,
            hotelActionType,
            hotelActionMinAccType,
            hotelActionDiscountType,
            hotelactioncommissiontype,
            includeType,
        };
    },
});
